<template>
  <div>
    <div class="flex items-center mb-5">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        Set Expiry Dates
      </h3>
      <hr class="flex-1 border-primary mx-4"/>
    </div>

    <div class="flex">
      <!-- Calibration Expiry Date -->
      <div class="w-1/3 pr-1">
        <label class="uppercase font-semibold text-sm mr-2">Calibration Expiry Date:</label>
        <Calendar
            dateFormat="dd/mm/yy"
            v-model="local_next_calibration" :showIcon="true"
        />
      </div>

      <!-- Service Expiry Date -->
      <div class="w-1/3 px-1">
        <label class="uppercase font-semibold text-sm mr-2">Service Expiry Date:</label>
        <Calendar
            dateFormat="dd/mm/yy"
            v-model="local_next_service" :showIcon="true"
        />
      </div>

      <!-- Update Button -->
      <div class="w-1/3 pl-1 flex items-end">
        <AppButton color="green" label="Update" @click="updateDates" :icon=true class="mr-2"
                   badgeType="success">
          <template v-slot:icon>
            <Icon iconType="save" class="mr-2"/>
          </template>
        </AppButton>
      </div>
    </div>
  </div>
</template>


<script>
import AppButton from "@/components/Button.vue";
import Icon from "@/components/Icon.vue";
import notificationMixin from "@/mixins/notificationMixin";
import EquipmentService from "@/services/equipmentService";

export default {
  name: "ExpiryDates",
  mixins: [notificationMixin],
  components: {Icon, AppButton},
  props: {
    data: Object,
  },
  data() {
    return {
      local_next_service: null,
      local_next_calibration: null
    };
  },
  mounted() {
    this.initializeDates();
  },
  methods: {
    async updateDates() {

      try {

        const next_service_unix = this.$moment(this.local_next_service).unix();
        const next_calibration_unix = this.$moment(this.local_next_calibration).unix();

        const expiryDates = {
          next_service: next_service_unix,
          next_calibration: next_calibration_unix,
        };

        const equipmentId = this.data.equipment_id;

        const result = await this.EquipmentService.updateExpiryDates(equipmentId, expiryDates);

        this.notifySuccess("Expiry dates updated successfully.");

        this.$emit('expiryDatesUpdated', result);

      } catch (error) {
        const errorMessage = error && error.data && error.data.message ? error.data.message : "Failed to update expiry dates.";
        this.notifyError(errorMessage);
      }
    },
    initializeDates() {
      if (this.data && this.data.next_service) {
        this.local_next_service = this.$moment.unix(this.data.next_service).toDate();
      } else {
        this.local_next_service = this.$moment().toDate();
      }

      if (this.data && this.data.next_calibration) {
        this.local_next_calibration = this.$moment.unix(this.data.next_calibration).toDate();
      } else {
        this.local_next_calibration = this.$moment().toDate();
      }
    },
  }
};
</script>
